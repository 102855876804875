@use "./button/button";
@use "./card/card";
@use "./checkbox/checkbox";
@use "./datepicker/datepicker";
@use "./dialog/dialog";
@use "./dropdown/dropdown";
@use "./iconbutton/iconbutton";
@use "./iconfield/iconfield";
@use "./inputtext/inputtext";
@use "./inputtextarea/inputtextarea";
@use "./messages/messages";
@use "./radiobutton/radiobutton";
@use "./selectbutton/selectbutton";
@use "./togglebutton/togglebutton";
@use "./tooltip/tooltip";
@use "./menu/menu";

@use "../design-tokens/colors";
@use "../design-tokens/typography";

.p-component {
  font-family: 'Inter', sans-serif;
}

:root {
  --p-anchor-gutter: 4px;
}

@each $color, $value in colors.$colors {
  .pb-text-#{$color} {
    color: $value;
  }

  .pb-bg-#{$color} {
    background-color: $value;
  }

  .pb-border-#{$color} {
    border-color: $value;
  }
}
