@use "sass:map";
@use "../../design-tokens/mixins/shadow";
@use "../../design-tokens/colors";
@use "../../design-tokens/typography";
@use "../../primeng-styling/iconbutton/iconbutton";
@use "../../design-tokens/mixins/glow";

// Message
.p-message {
  --p-message-border-width: 1px;
  --p-message-border-radius: 0.25rem;

  padding: 0.75rem 1rem;
  margin: 1rem;
  position: relative;
  width: 32.5rem;

  .p-message-content {
    display: grid;
    grid-template-columns: auto 1fr; /* Two columns: left auto width, right takes remaining space */
    grid-template-rows: auto auto; /* Two rows: one for each right div */

    .p-message-text:first-child {
      grid-column: 2; /* Place in the second column */
      grid-row: 1; /* First row */

      @include typography.pb-body-medium;
    }

    .p-message-text:nth-child(2) {
      grid-column: 2; /* Place in the second column */
      grid-row: 2; /* second row */

      @include typography.pb-body;
    }

    .p-message-icon {
      grid-column: 1; /* Place in the first column */
      grid-row: 1 / 3; /* Span two rows to stay on the left */

      font-size: 1.875rem;
      margin-right: 1rem;
    }

    .p-message-close-button {

      grid-column: 3; /* Place in the first column */
      grid-row: 1 / 3; /* Span two rows to stay on the left */
      width: 1.875rem;
      height: 1.875rem;
      border-radius: 50%;
      position: absolute;
      right: 16px;
      top: 16px;

      svg{
        width: 1rem;
        height: 1rem;
      }

      &:focus-visible {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none;
      }
    }
  }

  .p-icon {
    width: 0.875rem;
    height: 0.875rem;
  }
}

.overlay .p-message {
  @include shadow.shadow-high(rgba(0, 0, 0, 0.15));
  padding: 1rem;
  .p-message-wrapper{
    .p-message-summary {
      @include typography.h2;
    }
  }
}

$severity: (
  "info": "blue",
  "success": "green",
  "warning": "yellow",
  "error": "red",
);

@each $message, $color in $severity {

  .p-message.p-message-#{$message} {
    background-color: map.get(colors.$colors, "#{$color}-100");
    color: map.get(colors.$colors, "#{$color}-800");
    border-color: map.get(colors.$colors, "#{$color}-300");
    border-width: 1px;

    .p-icon {
      color: map.get(colors.$colors, "#{$color}-700");
    }

    .p-message-close{

      &:hover {
        background: map.get(colors.$colors, "#{$color}-200");
      }

      // Pressed
      &:active {
        background:  map.get(colors.$colors, "#{$color}-300");
      }

      // Focus with keyboard
      &:focus-visible {
        @include glow.glow(map.get(colors.$colors, "#{$color}-600"));
      }
    }
  }
}
