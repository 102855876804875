@use "sass:map";
@import '../../design-tokens/colors';

.p-datepicker {
  padding: 0.5rem;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #e5e5e5;
  border-radius: 4px;
}
.p-datepicker:not(.p-datepicker-inline) {
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
  background: #ffffff;
}
.p-datepicker .p-datepicker-header {
  padding: 0.5rem;
  color: rgba(0, 0, 0, 0.87);
  background: #ffffff;
  font-weight: 500;
  margin: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev,
.p-datepicker .p-datepicker-header .p-datepicker-next {
  width: 2.5rem;
  height: 2.5rem;
  color: rgba(0, 0, 0, 0.6);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
  color: rgba(0, 0, 0, 0.6);
  border-color: transparent;
  background: rgba(0, 0, 0, 0.04);
}
.p-datepicker .p-datepicker-header .p-datepicker-prev:focus,
.p-datepicker .p-datepicker-header .p-datepicker-next:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-datepicker .p-datepicker-header .p-datepicker-title {
  line-height: 2.5rem;
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year,
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  color: rgba(0, 0, 0, 0.87);
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  font-weight: 500;
  padding: 0.5rem;
}
.p-datepicker
  .p-datepicker-header
  .p-datepicker-title
  .p-datepicker-year:enabled:hover,
.p-datepicker
  .p-datepicker-header
  .p-datepicker-title
  .p-datepicker-month:enabled:hover {
  color: map.get($colors, "blue-600");
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  margin-right: 0.5rem;
}
.p-datepicker table {
  font-size: 1rem;
  margin: 0.5rem 0;
}
.p-datepicker table th {
  padding: 0.5rem;
}
.p-datepicker table th > span {
  width: 2.5rem;
  height: 2.5rem;
}
.p-datepicker table td {
  padding: 0.5rem;
}
.p-datepicker table td > span {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  transition: none;
  border: 1px solid transparent;
}
.p-datepicker table td > span.p-highlight {
  color: map.get($colors, "blue-600");
  background: rgba(63, 81, 181, 0.12);
}
.p-datepicker table td > span:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-datepicker table td.p-datepicker-today > span {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border-color: black;
}
.p-datepicker table td.p-datepicker-today > span.p-highlight {
  color: #3f51b5;
  background: rgba(63, 81, 181, 0.12);
}
.p-datepicker .p-datepicker-buttonbar {
  padding: 1rem 0;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.p-datepicker .p-datepicker-buttonbar .p-button {
  width: auto;
}
.p-datepicker .p-timepicker {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  padding: 0.5rem;
}
.p-datepicker .p-timepicker button {
  width: 2.5rem;
  height: 2.5rem;
  color: rgba(0, 0, 0, 0.6);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-datepicker .p-timepicker button:enabled:hover {
  color: rgba(0, 0, 0, 0.6);
  border-color: transparent;
  background: rgba(0, 0, 0, 0.04);
}
.p-datepicker .p-timepicker button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-datepicker .p-timepicker button:last-child {
  margin-top: 0.2em;
}
.p-datepicker .p-timepicker span {
  font-size: 1.25rem;
}
.p-datepicker .p-timepicker > div {
  padding: 0 0.5rem;
}
.p-datepicker.p-datepicker-timeonly .p-timepicker {
  border-top: 0 none;
}
.p-datepicker .p-monthpicker {
  margin: 0.5rem 0;
}
.p-datepicker .p-monthpicker .p-monthpicker-month {
  padding: 0.5rem;
  transition: none;
  border-radius: 4px;
}
.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
  color: map.get($colors, "blue-600");
  background: rgba(63, 81, 181, 0.12);
}
.p-datepicker .p-yearpicker {
  margin: 0.5rem 0;
}
.p-datepicker .p-yearpicker .p-yearpicker-year {
  padding: 0.5rem;
  transition: none;
  border-radius: 4px;
}
.p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight {
  color: map.get($colors, "blue-600");
  background: rgba(63, 81, 181, 0.12);
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  padding-top: 0;
  padding-bottom: 0;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {
  padding-left: 0;
  border-left: 0 none;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
  padding-right: 0;
}
.p-datepicker:not(.p-disabled)
  table
  td
  span:not(.p-highlight):not(.p-disabled):hover {
  background: rgba(0, 0, 0, 0.04);
}
.p-datepicker:not(.p-disabled)
  table
  td
  span:not(.p-highlight):not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-datepicker:not(.p-disabled)
  .p-monthpicker
  .p-monthpicker-month:not(.p-disabled):not(.p-highlight):hover {
  background: rgba(0, 0, 0, 0.04);
}
.p-datepicker:not(.p-disabled)
  .p-monthpicker
  .p-monthpicker-month:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-datepicker:not(.p-disabled)
  .p-yearpicker
  .p-yearpicker-year:not(.p-disabled):not(.p-highlight):hover {
  background: rgba(0, 0, 0, 0.04);
}
.p-datepicker:not(.p-disabled)
  .p-yearpicker
  .p-yearpicker-year:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

p-calendar.p-calendar-clearable .p-inputtext {
  padding-right: 3rem;
}
p-calendar.p-calendar-clearable .p-calendar-clear-icon {
  color: rgba(0, 0, 0, 0.6);
  right: 1rem;
}

p-calendar.p-calendar-clearable.p-calendar-w-btn .p-calendar-clear-icon {
  color: rgba(0, 0, 0, 0.6);
  right: 4rem;
}

@media screen and (max-width: 769px) {
  .p-datepicker table th,
  .p-datepicker table td {
    padding: 0;
  }
}

p-calendar .p-datepicker-trigger {
  height: 2.5rem;
  background: transparent;
  color: $pb-blue;
  position: absolute;
  right: 0px;

  &:hover{
    background: transparent !important;
  }
}
