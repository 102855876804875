@use "sass:map";
@use '../../design-tokens/colors';
@use '../../design-tokens/typography';

.p-radiobutton {
  &:hover {
    box-shadow: 0 0 0 32px map.get(colors.$colors, "blue-100");
    background: map.get(colors.$colors, "blue-100");
    clip-path: circle(100%);
  }

  &:active {
    box-shadow: 0 0 0 32px map.get(colors.$colors, "blue-200");
    background: map.get(colors.$colors, "blue-200");
    clip-path: circle(100%);
  }

  &.p-focus::before {
    content: '';
    position: absolute;
    box-shadow: 0 0 .25rem 1px map.get(colors.$colors, "blue-500");
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
  }

  &.p-radiobutton-checked {
    border: 1.5px solid map.get(colors.$colors, "blue-700");
    border-radius: 50%;
    background: map.get(colors.$colors, "grey-100");

    .p-radiobutton-icon {
      width: .5rem;
      height: .5rem;
      transition-duration: 0.2s;
      background-color: map.get(colors.$colors, "blue-700");
    }

    &.p-disabled {
      border: 1.5px solid map.get(colors.$colors, "blue-300");

      .p-radiobutton-icon {
        background-color: map.get(colors.$colors, "blue-300");
      }
    }

    &:hover {
      background: map.get(colors.$colors, "blue-100");
    }

    &:active {
      background: map.get(colors.$colors, "blue-200");
    }
  }

  &.p-radiobutton-disabled:hover {
    cursor: default;
  }

  .p-radiobutton-box {
    border: 1px solid map.get(colors.$colors, "grey-400");
    width: 1rem;
    height: 1rem;
    color: rgba(255, 255, 255, 0.87);
    border-radius: 50%;

    &:not(.p-highlight).p-disabled {
      border: 1px solid map.get(colors.$colors, "grey-400");
      background: map.get(colors.$colors, "grey-300");
    }
  }
}
