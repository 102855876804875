@use "sass:map";
@use "../../design-tokens/colors";
@use "../../design-tokens/typography";
@use "../../design-tokens/mixins/shadow";

.p-dialog-mask {
  background: rgba(0, 0, 0, 0.5);
}

.p-dialog {
  background: white;
  width: 100%;
  max-width: 520px;
  border: 1px solid map.get(colors.$colors, "grey-300");
  border-radius: 0.25rem;
  @include shadow.shadow-high;
  color: map.get(colors.$colors, "grey-800");

  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: auto 1fr;
  column-gap: 1.5rem;

  .p-dialog-header {
    grid-row: 1;
    grid-column: 2;
    align-items: flex-start;
    justify-content: space-between;

    .p-dialog-title {
      @include typography.h1;
      margin-inline-start: 1.5rem;
      margin-block-start: 1.5rem;
      margin-block-end: 0.75rem;
    }

    .p-dialog-header-actions {
      width: 2.5rem;
      height: 2.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-inline-end: 0.5rem;
      margin-block-start: 0.5rem;
      margin-inline-start: 0.5rem;
    }
  }

  .p-dialog-content {
    @include typography.pb-body;
    grid-row: 2;
    grid-column: 2;
    margin-inline-start: 1.5rem;
    margin-inline-end: 1.5rem;
    margin-block-end: 2rem;


  }

  .p-dialog-footer {
    grid-row: 3;
    grid-column: 2;
    margin-inline-end: 1.5rem;
    margin-block-end: 1.5rem;
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }

  &.danger .p-dialog-content{
    margin-left: 4.625rem;
  }

  &.danger .p-dialog-header {
    column-gap: 1rem;

    justify-content: normal;
    width: 100%;

    .p-dialog-title {
      margin-left: 0px;
    }

    &::before {
      color: map.get(colors.$colors, "red-700");
      width: 2.5rem;
      height: 2.5rem;
      display: flex;
      margin-inline-start: 1rem;
      margin-block-start: 1rem;
      font-size: 1.75rem;
      font-family: "Font Awesome 6 Pro";
      content: "\f071";
      align-items: center;
      justify-content: center;
    }

    .p-dialog-header-actions{
      position: absolute;
      right: 0;
    }
  }
}
