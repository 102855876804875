@use "sass:map";
@use "../../design-tokens/colors";
@use "../../design-tokens/typography";

.p-tooltip {
  --p-tooltip-gutter: 4px;
  --p-tooltip-background: transparent;
  max-width: 25rem;

  &.p-tooltip-right {
    transform: translateX(4px);

    .p-tooltip-arrow {
      border-right-color: map.get(colors.$colors, "grey-800");
    }
  }

  &.p-tooltip-left {
    transform: translateX(-4px);

    .p-tooltip-arrow {
      border-left-color: map.get(colors.$colors, "grey-800");
    }
  }

  &.p-tooltip-bottom {
    transform: translateY(4px);

    .p-tooltip-arrow {
      border-bottom-color: map.get(colors.$colors, "grey-800");
    }
  }

  &.p-tooltip-top {
    transform: translateY(-4px);

    .p-tooltip-arrow {
      border-top-color: map.get(colors.$colors, "grey-800");
    }
  }

  .p-tooltip-text {
    @extend .pb-body;
    background: map.get(colors.$colors, "grey-800");
    color: #fff;
    padding: .75rem;
    border-radius: 4px;
  }
}

// style class for fixed width
.pb-tooltip-fixed-width {
  width: 25rem !important;
}
