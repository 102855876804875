@import "bootstrap/scss/mixins";
@import "variables";

html {
  -moz-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
}

body {
  overscroll-behavior: none;
}

// @formatter:off - Should be 100dvh
// noinspection CssInvalidPropertyValue
.new-mca {
  height: 100dvh;
  display: flex;
  flex-direction: column;
}
// @formatter:on

@font-face {
  font-family: 'PortbaseIcons';
  src: url('./assets/fonts/pb-font-v11.woff') format('woff');
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  display: none;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 2px;
  border-radius: 2px;
  background: $grey-400;
}

::-webkit-scrollbar-thumb:hover {
  background: $grey-500;
}

::-webkit-scrollbar-thumb:active {
  background: $grey-600;
}

.pb-container-dropoff:before {
  font-family: 'PortbaseIcons', "Inter", sans-serif;
  font-size: 1.25rem !important;
  content: "\e90a";
  color: $grey-600;
  line-height: 1rem;
}

.pb-container-pickup:before {
  font-family: 'PortbaseIcons', "Inter", sans-serif;
  font-size: 1.25rem !important;
  content: "\e909";
  color: $grey-600;
  line-height: 1rem;
}

.pb-cargo-crane:before {
  font-family: 'PortbaseIcons', "Inter", sans-serif;
  font-size: 1.25rem !important;
  content: "\e91f";
  color: $grey-600;
  line-height: 1rem;
}

.ngx-toastr {
  max-width: 600px;
  width: 100% !important;
  padding: 1rem 1.25rem 1rem 4.5rem !important;
  box-shadow: 0 2px 32px rgba(0, 0, 0, 0.15) !important;
}

.toast-container {
  font-family: "Inter", sans-serif !important;
  margin-top: 1rem;

  .toast-title {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500 !important;
    margin-bottom: 0.25rem;
  }

  .toast-message {
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 400;
    padding-right: 2rem;
  }

  .toast-close-button {
    top: 0 !important;
    right: 0 !important;
  }

  .toast-close-button * {
    visibility: hidden;
    display: block;
    width: 0;
    height: 0;

    &:before {
      font-family: "Font Awesome 6 Pro", "Inter", sans-serif;
      font-size: 1.5rem;
      line-height: 1.5rem;
      font-weight: 300;
      content: "\f00d";
      visibility: visible;
      float: right;
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
    }
  }

  .toast-success {
    background-image: none;
    background-color: $green-100 !important;
    border: 1px solid $green-300 !important;
    color: $green-800 !important;

    .toast-close-button * {
      &:before {
        color: $green-700 !important;
      }
    }
  }

  .toast-info {
    background-image: none;
    background-color: $blue-100 !important;
    border: 1px solid $blue-300 !important;
    color: $blue-800 !important;

    .toast-close-button * {
      &:before {
        color: $blue-700 !important;
      }
    }
  }

  .toast-warning {
    background-image: none;
    background-color: $yellow-100 !important;
    border: 1px solid $yellow-300 !important;
    color: $yellow-800 !important;

    .toast-close-button * {
      &:before {
        color: $yellow-700 !important;
      }
    }
  }

  .toast-error {
    background-image: none;
    background-color: $red-100 !important;
    border: 1px solid $red-300 !important;
    color: $red-800 !important;

    .toast-close-button * {
      &:before {
        color: $red-700 !important;
      }
    }
  }

}

.toast-container > .ngx-toastr:before {
  font-family: "Font Awesome 6 Pro", "Inter", sans-serif;
  font-weight: 300;
  position: absolute;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;
  font-size: 2rem;
  line-height: 18px;
  margin-top: -1rem;
  margin-left: -3.125rem;
  min-height: 100%;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

#toast-container > .toast-success:before {
  content: "\f058";
  color: $green-700 !important;
}

#toast-container > .toast-info:before {
  content: "\f05a";
  color: $blue-700 !important;
}

#toast-container > .toast-warning:before {
  content: "\f06a";
  color: $yellow-700 !important;
}

#toast-container > .toast-error:before {
  content: "\f057";
  color: $red-700 !important;
}

.position-top-right {
  position: absolute !important;
  right: 0;
  margin: 0.5rem 0.5rem 0 0;

  &:hover::after { /* Fix for Chrome flickering issue with hovering over absolute position elements */
    content: '';
    position: absolute;
    height: 120%;
    width: 120%;
    left: -8px;
    top: -8px;
    box-sizing: border-box;
  }
}

.icon-button {
  font-size: 1rem;
  line-height: 2rem;
  width: 2rem;
  margin-right: 0.5rem;
  text-align: center !important;
  cursor: pointer;
  color: $grey-600;
}

.icon-button {
  &:hover {
    background-color: $grey-200;
    border-radius: 50%;
  }

  &:focus-visible {
    border-radius: 50%;
    outline: none !important;
    box-shadow: 0 0 5px 1px $blue-600 !important;
  }
}

.icon-button[disabled] {
  cursor: default;

  * {
    color: $grey-400;
  }

  &:hover {
    background-color: transparent;
  }
}

.new-mca-modal {
  font-family: "Inter", sans-serif !important;
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 500;

  .modal-dialog {
    transform: none !important;
  }

  .modal-content {
    border: 1px solid $grey-300;
    box-shadow: 0 2px 32px rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
  }

  .modal-header {
    border-bottom: 0;
    padding: 1.5rem 1.5rem 0 1.5rem;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .modal-body {
    margin-top: 0.75rem;
    font-size: 0.875rem;
    line-height: 1.3rem;
    font-weight: 400;
    color: $grey-700 !important;
    padding: 0 1.5rem;

    p {
      margin-bottom: 0.5rem;
    }
  }

  .modal-footer {
    margin-top: 1.5rem;
    padding: 0 1.5rem 1.5rem 1.5rem;
    border-top: 0;

    > * {
      margin: 0;
    }

    > button {
      margin-left: 1rem;
    }
  }
}

.modal {
  background: rgba(0, 0, 0, 0.5) !important;
}

.modal-backdrop {
  display: none !important;
}

app-edit-modal div.modal div.modal-dialog div.offcanvas-panel div.modal-body {
  overflow: auto;
  padding: 0 !important;
}

.offcanvas-panel {
  display: flex;
  flex-direction: column;
  height: 100svh;

  .nav-tabs-content {
    max-width: 880px;
    margin: 0 auto;
  }

  .modal-header {
    flex-direction: column;
    padding: 0;
    align-items: stretch;
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: 400;
  }

  .header-container {
    border-bottom: 1px solid $grey-300;
    padding: 1.5rem;
    height: 4.5rem;
  }

  .footer-container {
    width: 100% !important;
    background: transparent;
  }

  .button-bar {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding: 0 2rem;
  }

  .excel-container-content {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-top: 1rem;
  }

  .footer-container-content {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .button-bar button {
    margin: 0 0.5rem 0 1rem !important;
  }

  .button-bar-text {
    color: $grey-600;
    line-height: 0.75rem;
    width: 100%;
    padding: 0.875rem 1rem !important;
    margin: 0 0.5rem 0 1rem !important;
    font-size: 0.875rem;
  }

  .nav-tabs .nav-item, .nav-tabs .nav-item:hover, .nav-tabs .nav-item:focus, .nav-tabs .nav-item:active {
    border-top-width: 0 !important;
    border-left-width: 0 !important;
    border-right-width: 0 !important;
    border-radius: 0 !important;
    margin: 0 !important;
    font-size: 0.875rem;
    padding: 1.25rem 2rem 1.125rem !important;
    line-height: 1rem !important;
    font-weight: 500;
  }

  .nav-tabs .nav-item:active {
    background-color: transparent !important;
  }

  .nav-content {
    padding: 2rem 2rem;
    border-radius: 0.25rem;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.03);
  }

  .nav-content-header {
    font-size: 1.125rem;
    line-height: 1.25rem;
    margin-bottom: 1rem;
  }

  .section-break-line {
    margin: 2rem -2rem;
  }

  .section-group {
    padding-bottom: 1rem;
  }

  .section-group > .nav-content:not(.right-section, .single-section) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .section-group > .nav-content:not(.left-section, .single-section) {
    margin-left: -1px;
  }

  .section-group > .nav-content:not(.left-section, .single-section) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  label, .col-form-label {
    font-size: 0.875rem;
    line-height: 1rem !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  label:not(.form-check-label):not(app-yes-no label):not(app-toggle label) {
    font-weight: 500;
    margin-bottom: 0.5rem;
  }

  .form-group {
    margin-bottom: 1.5rem;
  }

  .btn-group-toggle:focus {
    outline-width: 0;
  }

}

app-new-mca .was-validated .form-control:invalid + .input-group-text button.btn {
  border: 1px solid red;
}

app-new-mca .ng-invalid.invalid-feedback, app-new-mca .was-validated .ng-invalid > .invalid-feedback {
  display: none !important;
}

app-new-mca .was-validated .ng-invalid.invalid-feedback, app-new-mca .was-validated .ng-invalid > .invalid-feedback {
  display: flex !important;
}

app-new-mca .new-mca, .offcanvas-panel, .new-mca-modal, app-main-panel {
  font-family: "Inter", sans-serif !important;
  font-size: 1rem;

  .beamer_icon.active, #beamerIcon.active {
    line-height: 19px;
    background-color: $blue-600 !important;
    color: transparent;
    width: 6px;
    height: 6px;
    margin-top: 2px;
    margin-right: 2px;
  }

  .text-secondary {
    color: $grey-500 !important;
  }

  .text-danger {
    color: $red-700 !important;
  }

  .select-all {
    user-select: all;
  }

  .prevent-select {
    user-select: none;
  }

  app-date-time .text-secondary {
    color: $grey-800 !important;
  }

  app-date-range .text-secondary {
    color: $grey-800 !important;
  }

  app-date-range {
    .custom-day.range {
      background-color: $blue-800 !important;
    }

    .custom-day:hover {
      background-color: $blue-800 !important;
    }

    .custom-day.faded {
      background-color: $grey-100 !important;
      color: $blue-800 !important;
    }
  }

  app-formatted-date-range .text-secondary {
    color: $grey-800 !important;
  }

  input[type='checkbox'], select {
    cursor: pointer;
  }

  .adjustable-width {
    width: 100%;
    display: inline-block;
  }

  input[type='text'], input[type='search'], input.form-control, app-new-select, app-date-time .date-field, app-date-time .time-field, .adjustable-width {
    &.sm {
      max-width: 160px !important;
    }

    &.md {
      max-width: 200px !important;
    }

    &.lg, &.lg-xl {
      max-width: 280px !important;
    }

    &.xl {
      max-width: 400px !important;
    }

    disabled {
      background-color: $grey-200;
    }
  }

  input[type='text'], input[type='search'], input.form-control, app-search .search-icon {
    line-height: 1rem;
    font-size: 0.875rem;
    padding-left: 1rem;
    height: 2.5rem;
    box-sizing: border-box;
  }

  input[type='text'], input[type='search'], ng-select:not(.tertiary) .ng-select-container, input.form-control {
    font-size: 0.875rem;
    padding-left: 1rem;
    height: 2.5rem;
    box-sizing: border-box;

    &:hover {
      outline: 1px solid $grey-500 !important;
      outline-offset: -1px;
      border-color: transparent !important;
      z-index: 1;
    }

    &:active {
      outline: 1px solid $blue-700 !important;
      outline-offset: -1px;
      border-color: transparent !important;
      box-shadow: none !important;
      z-index: 1;
    }

    &:focus-visible {
      outline: none !important;
      border: 1px solid white !important;
      box-shadow: 0 0 5px 1px $blue-600 !important;
      z-index: 1;
    }

    &:focus {
      outline: 1px solid $blue-700 !important;
      outline-offset: -1px;
      border-color: transparent !important;
      box-shadow: none !important;
      z-index: 1;
    }

    &.disabled, &:disabled {
      color: $grey-600;
      background-color: $grey-200;
    }
  }

  .ng-input input[type='text'] {
    outline: none !important;
    box-shadow: none !important;
    border-color: transparent !important;
  }

  /* datepicker */
  .ngb-dp-weekday {
    font-style: normal;
    color: $blue-700;
  }

  .ngb-dp-navigation-chevron {
    color: $blue-700;
  }

  .ngb-dp-day > .bg-primary {
    background-color: $blue-700 !important;
  }

  /* end datepicker */

  /* ngb typeahead styling */
  .dropdown-item.active {
    background-color: $grey-200;
    color: $grey-800;
  }

  .ngb-highlight {
    font-weight: 600;
  }

  ngb-typeahead-window {
    max-height: 248px;
    overflow-y: auto;
  }

  /* /end ngb typeahead styling */

  app-search .search-icon {
    display: flex;
    position: relative;
    left: -2.75rem;
  }

  app-search .fa-search:before {
    font-size: 1rem;
    content: "\f078" !important;
    font-weight: 300 !important;
    line-height: 1rem;
  }

  input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
    height: 1.25rem;
    width: 1.25rem;
    margin-right: -0.125rem;
    background: url(./assets/img/xmark-light.svg);
    background-size: contain;
    opacity: 0;
    cursor: default;
  }

  input[type="search"]:focus::-webkit-search-cancel-button {
    opacity: 0.7;
    cursor: pointer;
  }

  input[type="search"]:hover::-webkit-search-cancel-button {
    opacity: 0.7;
    cursor: pointer;
  }

  .spinner-border {
    border-width: 1px;
    color: $grey-600 !important;
  }

  ngb-tooltip-window.fade {
    transition: none !important;
  }

  ngb-tooltip-window.bs-tooltip-top-right .arrow,
  ngb-tooltip-window.bs-tooltip-bottom-right .arrow {
    left: auto;
    right: 0.4rem;
  }

  ngb-tooltip-window {
    top: 0.5rem !important;
  }

  .action-panel {
    box-shadow: inset 8px 0 0 0 $blue-800;
    background-color: transparent !important;
    border-bottom: 1px solid $grey-300;
    padding: 1rem 1.5rem 0;

    .action-description {
      color: $grey-600 !important;
    }
  }

  .visit-panel {
    background-color: $grey-200;
    border: 1px solid $grey-300;
  }

  .handling-panel {
    background-color: white;
    border: 1px solid $grey-300;
  }

  /** Start grid */
  .panel-grid {
    display: grid;
    grid-template-columns: 16px 32px 2fr 2fr 3fr 3fr 24px 96px;
    align-items: center;
    grid-gap: 0 1rem;
  }

  .grid-attach-to-visit-mode {
    display: grid;
    grid-template-columns: 32px 4fr 4fr 4fr 4fr 24px 192px;
    align-items: center;
    grid-gap: 0 1rem;
  }

  .grid-add-handling-to-visit-mode {
    display: grid;
    grid-template-columns: 32px 4fr 4fr 4fr 4fr 24px 192px;
    align-items: center;
    grid-gap: 0 1rem;
  }

  /** End grid */

  /** Start responsive view */
  .show-md-screen, .show-sm-screen {
    display: none !important;
  }

  @include media-breakpoint-down(lg, $grid-breakpoints) {
    .show-md-screen {
      display: block;
    }

    .hide-lg-screen {
      display: none !important;
    }

    .panel-grid {
      display: grid;
      grid-template-columns: 16px 32px 1fr 1fr 2fr 24px 64px;
      align-items: center;
    }

    .grid-attach-to-visit-mode {
      display: grid;
      grid-template-columns: 32px 2.2fr 2fr 3fr 24px 192px;
      align-items: center;
      grid-gap: 0 1rem;
    }

    .grid-add-handling-to-visit-mode {
      display: grid;
      grid-template-columns: 32px 2.2fr 2fr 3fr 24px 160px;
      align-items: center;
      grid-gap: 0 1rem;
    }
  }

  @include media-breakpoint-down(md, $grid-breakpoints) {
    input {
      font-size: 16px !important;
    }

    .hide-md-screen {
      display: none !important;
    }

    .show-sm-screen {
      display: block !important;
    }

    .panel-grid, {
      display: grid;
      grid-template-columns: 32px 2fr 2fr 24px;
      align-items: center;
    }

    .grid-add-handling-to-visit-mode {
      display: grid;
      grid-template-columns: 32px 2fr 24px 100px;
      align-items: center;
    }

    .grid-attach-to-visit-mode {
      display: grid;
      grid-template-columns: 32px 2fr 2fr 60px;
      align-items: center;
    }

    .overview-panel {
      padding: 0 0 !important;
    }

    .nav-content {
      padding: 1.5rem;
    }

    .modal-body .tab-pane {
      padding-top: 0 !important;
    }

    .section-group {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      display: block !important;
    }

    .section-group > .nav-content:not(.right-section, .single-section) {
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      border-top-right-radius: 0.25rem;
      border-bottom: 0 !important;
      padding-bottom: 0.75rem;
    }

    .section-group > .nav-content:not(.left-section, .single-section) {
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
      border-bottom-left-radius: 0.25rem;
      border-top: 0 !important;
      padding-top: 0;
      margin-left: 0 !important;
      margin-top: -1px !important;
    }

    .user-icon {
      border-left: 0 !important;
    }

    .action-panel {
      box-shadow: none;
      padding: 1rem 0 0;
    }

    .action-panel-header {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }

    .attach-handling {
      margin-bottom: 0 !important;
      border-bottom: 0;
    }

    .handling-panel {
      border-left: 0;
      border-right: 0;
    }

    .handlings > app-handling-panel > .handling-panel:not(.remain-on-truck-panel) {
      border-bottom: 0;
    }

    .section-break-line {
      margin: 1.5rem -1.5rem;
    }

    .beamer_icon.active, #beamerIcon.active {
      margin-right: -10px;
    }
  }

  .truncate {
    min-width: 48px;
  }

  /** End responsive view */


  .panel-icon {
    font-size: 1.25rem;
    text-align: center;
  }

  main {
    font-size: 0.875rem;
    line-height: 1rem;
    font-weight: 400;
    color: $grey-800;
  }

  .overview-navbar {
    background-color: $grey-100;
  }

  app-action-panel {
    background-color: $grey-100;
  }

  .overview-panel {
    padding: 1.5rem;
    background-color: $grey-100;
  }

  .dropdown-menu {
    box-shadow: 0 2px 32px rgba(0, 0, 0, 0.15);
    border-color: $grey-300;
  }

  .dropdown-item {
    cursor: pointer;
  }

  .dropdown-header {
    font-size: 0.75rem;
    line-height: 1rem;
    padding: 0.5rem 1rem;
    color: $grey-600;
    font-weight: 400;
  }

  .dropdown-divider {
    margin: 0.25rem 0;
    border-top: 1px solid $grey-300;
  }

  .dropdown-item {
    font-size: 0.875rem;
    line-height: 1rem;
    padding: 0.75rem 1rem;
    color: $grey-800;

    &:hover {
      background-color: $grey-200;
    }

    &:active {
      background-color: $grey-300;
      color: $grey-800 !important;
    }
  }

  .tooltip-inner {
    max-width: 500px;
    background-color: $grey-800;
    border-radius: 0.25rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    padding: 0.75rem;
  }

  .tooltip-card *, .accept-status-list *:not(.accept-status-icon *) {
    font-family: "Inter", sans-serif !important;
    text-align: left;
    color: white;
  }

  .tooltip-card:not(:last-of-type) {
    margin-bottom: 0.5rem;
  }

  .tooltip-card-title {
    color: white !important;
    font-size: 0.875rem;
    line-height: 1rem;
    font-weight: 500;
  }

  .tooltip-card-body {
    color: white !important;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  ngb-tooltip-window .arrow:before {
    border-top-color: $grey-800;
  }

  ngb-tooltip-window {
    opacity: 1 !important;
  }

  .sub-text {
    margin-top: 0.125rem;
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 400;
    color: $grey-600;
    vertical-align: bottom;
  }

  .datetime .input-group input {
    height: 2.5rem;
  }

  .overlay-icon {
    color: $grey-500;
    line-height: 1.5rem !important;
  }

  .nav-tabs .nav-item {
    background-color: transparent !important;
  }

  .nav-tabs {
    border-bottom: 0;
  }

  .modal-body .tab-pane {
    padding: 1.5rem 1rem 0 1rem;
  }

  .overflow-tooltip {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .tab-bar {
    background-color: $grey-100 !important;
  }

  .nav-content {
    background-color: white;
    border: 1px solid $grey-300 !important;
  }

  .nav-content input, .nav-content .ng-select-container {
    border-color: $grey-400;
  }

  .nav-content input:active, .nav-content input:focus, .nav-content .ng-select-container:active, .nav-content .ng-select-container:focus {
    border: 1px solid $blue-700 !important;
  }

  .nav-content .ng-input input:active, .nav-content .ng-input input:focus {
    border: transparent !important;
  }

  .nav-item {
    line-height: 2rem;
  }

  .modal-body .nav-item, .modal-body .section-header {
    color: $grey-600;
  }

  .nav-item.active {
    border-bottom: 0.125rem solid $blue-700 !important;
    color: $blue-700;
  }

  .canvas-extra-content {
    padding: 1rem;
    margin-bottom: 1rem;
    border: 1px solid $grey-300;
    border-radius: 0.25rem;
  }

  .notification {
    display: inline-flex;
    justify-content: center;
    font-size: 0.875rem;
    line-height: 1.5rem;
    width: 1.5rem;
    text-align: center !important;
    cursor: pointer;
    border-radius: 1rem;
    font-weight: 500;
  }

  .notification-danger {
    color: $red-800;
    background-color: $red-200;
  }

  .notification-warning {
    color: $yellow-800;
    background-color: $yellow-200;
  }

  .notification-info {
    color: $blue-800;
    background-color: $blue-200;
  }

  .validation {
    outline: 1px solid $yellow-700 !important;
  }

  .invalid-feedback, .validation-error {
    margin-top: 0.25rem;
    padding-bottom: 0.25rem;
    font-size: 0.8rem;
    line-height: 1rem;
  }

  .validation-errors, .invalid-feedback, .validation-error {
    color: $red-700;
  }

  .form-control:invalid {
    background-image: none !important;
  }

  .warning-icon {
    padding-left: 0.25rem;
    color: $yellow-600 !important;
  }

  .info-icon {
    padding-left: 0.25rem;
    color: $grey-600 !important;
    display: inline-block;
  }

  .details-row {
    color: $grey-800;
  }

  .details-row *:first-child {
    color: $grey-600;
  }

  .nav-content-header {
    color: black !important;
  }

  label {
    color: $grey-900 !important;
  }

  .tabs:not(.active) {
    color: $grey-600;
  }

  .tabs.active {
    color: $blue-800 !important;
  }

  .secondary-menu {
    .nav-link.active {
      background-color: $grey-200;
    }

    .nav-link:hover {
      background-color: $grey-300;
    }

    .nav-item-header {
      color: $grey-600;
    }

    .nav-icon * {
      color: $grey-600;
    }

    .nav-text {
      color: $grey-700;
    }
  }

  .nav-link {
    margin-top: 0.5rem;
    border-radius: 0.25rem;

    &:focus-visible {
      outline: 1px solid white !important;
      outline-offset: -2px;
      box-shadow: 0 0 5px 1px $blue-600 !important;
    }
  }

  .nav-text {
    display: inline-block;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500;
  }

  .nav-sub-text {
    display: inline-block;
    font-size: 0.875rem;
    line-height: 1rem;
    font-weight: 400;
  }

  .nav-icon {
    font-size: 1.25rem;
    display: inline-block;
    width: 45px;
    text-align: center;
  }

  .nav-item-header {
    font-size: 0.75rem;
    line-height: 1rem;
    text-transform: uppercase;
    font-weight: 400;
  }

  //navbar-top
  .brand {
    border-bottom: 1px solid $grey-600;
  }

  .user-icon, .page-title {
    border-bottom: 1px solid $grey-300 !important;
  }

  .user-icon {
    border-left: 1px solid $grey-300;
  }

  .user-icon-container {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    text-align: center;

    &:hover {
      background-color: $grey-200;
      border-radius: 50%;
    }

    &:focus-visible {
      border-radius: 50%;
      outline: none !important;
      box-shadow: 0 0 5px 1px $blue-600 !important;
    }
  }

  .btn:not(app-date-time .btn, .btn-tertiary) {
    padding: 0.75rem 1.25rem !important;
    white-space: nowrap;
    max-height: 2.5rem;
  }

  .btn:not(app-date-time .btn) {
    font-size: 0.875rem !important;
    line-height: 1rem !important;
    font-weight: 500 !important;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.03);

    &:focus-visible {
      outline: 1px solid white;
      box-shadow: 0 0 5px 1px $blue-600 !important;
    }
  }

  .ngb-dp-arrow-btn {
    box-shadow: none !important;
  }

  // Primary button style
  .btn-primary {
    background-color: $blue-700 !important;
    color: white !important;

    .button-icon {
      color: $blue-200;
    }

    &:hover {
      background-color: $blue-800 !important;
    }

    &:active {
      background-color: $blue-900 !important;
    }

  }

  // Secondary button style
  .btn-outline-primary {
    color: $blue-700 !important;
    border-color: $blue-200 !important;

    &:hover {
      background-color: $blue-100 !important;
    }

    &:active {
      background-color: $blue-200 !important;
    }
  }

  button {
    &:disabled {
      pointer-events: none;
    }
  }

  button.btn.btn-secondary span {
    color: $blue-700 !important;
  }

  label.btn.btn-secondary:has(input:checked) {
    background-color: $blue-100 !important;
    border-color: $blue-500 !important;
    z-index: 2;

    &:hover {
      color: $blue-800 !important;
      border-color: $blue-600 !important;
    }

    &:active {
      color: $blue-800 !important;
      border-color: $blue-600 !important;
    }
  }

  .btn-group {
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.03);

    .btn {
      box-shadow: none;
    }
  }

  label.btn.btn-secondary:has(input:not(:checked)) {
    font-weight: 400 !important;
    color: $grey-700 !important;
    border-color: $grey-400 !important;

    &:hover {
      color: $grey-800 !important;
      border-color: $grey-500 !important;
      background-color: inherit !important;
    }
  }

  label.btn.btn-secondary.focus:has(input:checked) {
    box-shadow: none;
    z-index: 3;
  }

  label.btn.btn-secondary:has(input:focus-visible) {
    box-shadow: 0 0 5px 1px $blue-600 !important;
  }

  .btn-secondary {
    background-color: white !important;
    color: $blue-700 !important;
    border: 1px solid $blue-200 !important;

    .button-icon {
      color: $blue-400;
    }

    &:hover {
      background-color: $blue-100 !important;
    }

    &:active {
      background-color: $blue-200 !important;
    }

  }

  .btn-tertiary {
    background-color: transparent !important;
    color: $blue-700 !important;
    border: 0;
    box-shadow: none !important;
    padding: 0.75rem 0 !important;

    .button-icon {
      color: $blue-400;
    }

    &:hover {
      text-decoration: underline;
      background-color: transparent !important;
    }

    &:active {
      text-decoration: underline;
      color: $blue-800 !important;
      background-color: transparent !important;
    }
  }

  .overview-navbar app-overview-filter input:not(:focus, :hover) {
    color: $grey-600;

    border-color: $grey-300 !important;

    &:focus-visible {
      border-color: $grey-600 !important;
    }
  }

  .accept-status-list hr {
    background-color: $grey-600;
  }

  .status-icon-green {
    color: $green-600 !important;
  }

  .status-icon-red {
    color: $red-600 !important;
  }

  .status-icon-blue {
    color: $blue-600 !important;
  }

  .status-icon-grey {
    color: $grey-500 !important;
  }

  .status-icon-white {
    color: white !important;
  }

  // Checkbox-styling

  .form-check .form-check-input {
    margin-top: .25rem;
    margin-left: -1.5rem !important;
    width: 1rem;
    height: 1rem;
    border-color: $grey-400 !important;
  }

  .form-check .form-check-input:checked, .form-check .form-check-input:indeterminate {
    border-color: $blue-700 !important;
    background-color: $blue-700 !important;
  }

  .form-check .form-check-input:focus, .form-check .form-check-input:active {
    border-color: $grey-400 !important;
    box-shadow: 0 0 3px 4px $blue-200 !important;
  }

  .form-check .form-check-input:hover {
    box-shadow: 0 0 3px 4px $blue-100 !important;
  }

  .form-check .form-check-input:focus-visible {
    box-shadow: 0 0 5px 1px $blue-600 !important;
  }


}


/* ------------------------------- */


.bg-portbase-light-blue {
  background-color: #EDF0F2;
}

.border-grey {
  border-color: $grey-300 !important;
}

.clickable {
  cursor: pointer;
}

.h7 {
  text-transform: uppercase;
  font-size: 0.6875rem;
  font-weight: bold;
}

.portbase-light-blue, .sidebar.portbase-light-blue .nav-item, .sidebar.portbase-light-blue .nav-link {
  color: #EDF0F2;
}

.bg-portbase-lighter-grey {
  background-color: #F9FAFB
}

.bg-portbase-medium, .btn-primary {
  background-color: #005494 !important;
  border-color: #005494 !important;
}

.btn {
  padding: 4px 15px !important;
}

.bg-portbase-dark, .nav-link.active {
  background-color: #004478;
}

#main-menu-screenshot-root > .row {
  min-height: 100vh;
}

//override theme defaults
$label-margin-bottom: 0;

// voor backwards compatibility maar aangezet, eigenlijk zijn negatieve margins iets wat je beter moet vermijden.
$enable-negative-margins: true;

//don't show green validation success color
$form-feedback-valid-color: #ced4da;
$form-feedback-icon-valid: "";

// Bootstrap 5 adjustments

.btn-group-toggle > .btn input[type=radio] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.datetime .input-group-text {
  background-color: white;
}

.bullet .spacer {
  display: block;
  height: 0;
  color: transparent;
}

button.btn-info {
  color: white;
}

button.btn-info:hover, button.btn-info:focus {
  color: white;
  background-color: #138496;
  border-color: #117a8b;
}

$info: #17a2b8;

.custom-check-input {
  position: static;
  margin-top: .3rem;
  margin-left: -1.25rem;
}

.custom-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.custom-check-inline .custom-check-input {
  position: static;
  margin-top: 0;
  margin-right: .3125rem;
  margin-left: 0;
}

.form-group {
  margin-bottom: 1rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col {
  padding-left: 5px;
  padding-right: 5px;
}

//end-bootstrap 5 adjustments


@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";

$pagination-color: $info;
$pagination-active-bg: $info;

$link-color: $info;
$link-hover-color: shade-color($link-color, 15%);
$nav-pills-link-active-bg: $info;

@import "bootstrap/scss/bootstrap";

.z-top {
  z-index: 10000;
}

.narrow-gutters {
  margin-right: -5px;
  margin-left: -5px;

  > .col,
  > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
  }
}

.cursor-pointer {
  cursor: pointer !important;
}

//no highlighting
input:focus, button:focus, a:focus {
  outline: none;
  outline-width: 0;
  box-shadow: none;
}

.page-section {
  max-width: 1440px;
  margin: auto;
}

.was-validated .custom-check-input:valid ~ .form-check-label, .custom-check-input.is-valid ~ .form-check-label {
  color: $input-color !important;
}


.no-transition {
  transition: none !important;
}

.input-group-text {
  @extend .rounded-end;

  display: inline-block;
  text-align: center;
  min-width: 3rem;
}

// Portbase colors according to corporate identity

.font-weight-medium {
  font-weight: 500 !important;
}

.bg-portbase-dark-blue {
  background-color: #004478 !important;
}

.bg-portbase-turquoise {
  background-color: #19a2ae !important;
}

.bg-portbase-orange {
  background-color: #ec6726 !important;
}

.bg-portbase-black {
  background-color: #000000 !important;
}

.bg-portbase-aqua {
  background-color: #8ed1df !important;
}

.bg-portbase-red {
  background-color: #e52938 !important;
}

.bg-portbase-dark-red {
  background-color: #7e003f !important;
}

.bg-portbase-fuchsia {
  background-color: #e70f7a !important;
}

.bg-portbase-very-light-aqua {
  background-color: tint-color(#8ed1df, 30) !important;
}

.text-portbase-dark-blue {
  color: #004478 !important;
}

.text-portbase-turquoise {
  color: #19a2ae !important;
}

.text-portbase-orange {
  color: #ec6726 !important;
}

.text-portbase-black {
  color: #000000 !important;
}

.text-portbase-aqua {
  color: #8ed1df !important;
}

.text-portbase-red {
  color: #e52938 !important;
}

.text-portbase-dark-red {
  color: #7e003f !important;
}

.text-portbase-fuchsia {
  color: #e70f7a !important;
}

.text-portbase-very-light-aqua {
  color: tint-color(#8ed1df, 30) !important;
}

.text-portbase-green {
  color: #27ae60 !important;
}

.banner-green {
  background-color: #27ae60 !important;
}

.input-uppercase {
  text-transform: uppercase;
}

// declaration menu
.declaration-block h2 {
  font-size: 0.875rem;
  font-weight: bold;
  text-transform: uppercase;
  color: #666;
  padding: 5px 0;
  margin: 20px 0 10px;
  border-bottom: 2px solid #666;
}

//cancelled
.cancelled {
  background: repeating-linear-gradient(
      45deg,
      white,
      white 10px,
      $light 10px,
      $light 20px
  );
}

.text-light {
  color: $light;
}

.strikethrough {
  text-decoration: line-through;
}

.required::after {
  padding-left: 0.25rem;
  content: '*';
}

.optional::after {
  @extend small;
  padding-left: 0.25rem;
  content: '(Optional)';
  color: $secondary;
}

// to disable up down arrows in input fields of type number
.no-spin::-webkit-inner-spin-button, .no-spin::-webkit-outer-spin-button {
  appearance: none !important;
  margin: 0 !important;
}

// drag and drop cursors
.grabbable {
  cursor: grab;
}

/* Apply a "closed-hand" cursor during drag operation. */
.grabbable:active, .gu-mirror .grabbable {
  cursor: grabbing;
}

/* Edit button icons */
.fa-edit {
  cursor: pointer;
}

.fa-edit:hover {
  color: shade-color($info, 7.5%) !important;
}

/* automatically show validation feedback in custom components */
.was-validated {
  .ng-invalid + .invalid-feedback, .invalid.invalid-feedback, .valid.valid-feedback {
    display: block !important;
  }

  /* for cases where custom components set 'ng-invalid' manual */
  .ng-invalid.invalid-feedback, .ng-invalid > .invalid-feedback {
    display: block !important;
  }

  .validatable.ng-invalid {
    @extend .border;
    @extend .border-danger;
    @extend .rounded;
  }

  //hide valid validation
  .form-control:valid, .form-control.is-valid {
    padding-right: 0.75rem !important;
  }

  ngb-datepicker-navigation-select > .custom-select:valid {
    padding: 0 0.5rem !important;
  }

  .form-control:invalid, .form-control.is-invalid {
    padding-right: 1.75rem !important;
  }

  .btn-invalid {
    @extend .btn-danger
  }
}

/* fixes for ie styling */
input[type=text]::-ms-clear {
  display: none;
}

.disabled {
  input, select, textarea, button, .custom-check {
    pointer-events: none;
  }

  .form-control {
    background-color: #e9ecef;
    opacity: 1;
  }

  .custom-select {
    color: #6c757d;
    background-color: #e9ecef;
  }

  .btn, .custom-check {
    opacity: 0.65;
  }

  //make sure button labels still work
  label.btn {
    opacity: 1;
    pointer-events: auto;
    cursor: auto;
  }

  .enabled, .always-enabled {
    input, select, textarea, button, .custom-check {
      pointer-events: auto;
    }

    .form-control {
      background-color: #ffffff;
    }

    .custom-select {
      color: #495057;
      background-color: #ffffff;
    }

    .btn, .custom-check {
      opacity: 1;

      &:disabled {
        opacity: 0.65;
      }
    }
  }
}

.disabled input {
  &::-webkit-input-placeholder {
    opacity: 0;
  }

  &:-moz-placeholder {
    opacity: 0;
  }

  &::-moz-placeholder {
    opacity: 0;
  }

  &:-ms-input-placeholder {
    opacity: 0;
  }

  &::placeholder {
    opacity: 0;
  }
}


.disabled .disabled .enabled {
  input, select, textarea, button, .custom-check {
    pointer-events: none;
  }

  .form-control {
    background-color: #e9ecef;
    opacity: 1;
  }

  .custom-select {
    color: #6c757d;
    background-color: #e9ecef;
  }

  .btn, .custom-check {
    opacity: 0.65;
  }

  //make sure button labels still work
  label.btn {
    opacity: 1;
    pointer-events: auto;
    cursor: auto;
  }

  .always-enabled {
    input, select, textarea, button, .custom-check {
      pointer-events: auto;
    }

    .form-control {
      background-color: #ffffff;
    }

    .custom-select {
      color: #495057;
      background-color: #ffffff;
    }

    .btn, .custom-check {
      opacity: 1;

      &:disabled {
        opacity: 0.65;
      }
    }
  }
}

#beamerSelector {
  display: none !important;
}

.beamer_icon.active, #beamerIcon.active {
  background-color: #e52938 !important;
}

body:has(app-bhg-signed-out) .beamerAnnouncementPopupContainer {
  display: none;
}

body:has(app-bhg-login) .beamerAnnouncementPopupContainer {
  display: none;
}

// regular style toast
@import 'ngx-toastr/toastr';

// bootstrap style toast
// or import a bootstrap 5 alert styled design (SASS ONLY)
// should be after your bootstrap imports, it uses bs4 variables, mixins, functions
// NB: scss is copied from ngx-toastr, when upgrading angular fetch it from ngx-toastr itself again
@import 'toastr-bs5-alert';

// stylable select form controls
@import "@ng-select/ng-select/themes/default.theme.css";

.vk-green {
  background: url("./assets/img/pb-shield-halved-check-duo.svg") no-repeat, none;
  background-size: 100%;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-left: 5px;
  vertical-align: bottom;
}

.vk-blue {
  background: url("./assets/img/pb-arrow-down-to-square-progress-duo.svg") no-repeat, none;
  background-size: 100%;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-left: 5px;
  vertical-align: bottom;
}
