@use "sass:map";
@use "../../design-tokens/mixins/shadow";
@import '../../design-tokens/colors';
@import '../../design-tokens/typography';

$card-width: 800px;
$card-border-radius: 4px;
$card-padding: 2rem;


.p-card {
  @include shadow.shadow-low;
  background: map.get($colors, "white");
  border-radius: $card-border-radius;
  border: 1px solid map.get($colors, "grey-300");
  width: $card-width;

  &-body {
    padding: $card-padding;
  }

  &-title {
    @extend h1;
    color: map.get($colors, "grey-800");
  }

  &-content {
    @extend .pb-body-compact;
    color: map.get($colors, "grey-800");
  }
}
