@use "sass:map";
@use "../../design-tokens/colors";
@use "../../design-tokens/typography";

p-menu .p-menu {
  height: 100%;
  width: 17rem;
  min-width: unset;
  background: colors.$pb-blue;
  user-select: none;

  // Logo container
  div.p-menu-start {
    height: 4.5rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;

    &:has(> a) {
      cursor: pointer;

      &:hover {
        background-color: map.get(colors.$colors, "blue-900");
      }

      &:active {
        background-color: map.get(colors.$colors, "blue-850");
      }

      a {
        display: flex;
        align-items: center;
        justify-content: center;

        > :last-child {
          display: none;
        }
      }
    }

    &:not(:has(> a)) {
      > :last-child {
        display: none;
      }
    }

    img {
      width: 7.5rem;
    }
  }

  // Menu items
  ul.p-menu-list {
    padding-inline: 1rem;
    margin-block-end: 2rem;

    li.p-menu-submenu-label {
      height: 3rem;
      display: flex;
      align-items: flex-end;
      opacity: 0.6;
      color: white;
      @include typography.pb-label-uppercase;

      span {
        padding-inline-start: 1rem;
        margin-block-end: 0.5rem;
      }
    }

    li.p-menu-item {
      border-radius: 0.25rem;

      &.p-disabled {
        opacity: 0.25;
      }

      &.p-menuitem-link-active {
        background-color: map.get(colors.$colors, "blue-850");
      }

      &:hover {
        background-color: map.get(colors.$colors, "blue-900");
      }

      &:active {
        background-color: map.get(colors.$colors, "blue-850");
      }

      .p-menu-item-content .p-menu-item-link {
        height: 3.5rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-inline-start: 1rem;
        gap: 1rem;
        color: white;
        opacity: 0.9;
        @include typography.h2;

        .p-menu-item-icon {
          height: 2rem;
          width: 2rem;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.5rem;
        }
      }
    }
  }

  // Collapse button
  div.p-menu-end {
    height: 4.5rem;

    label > :last-child {
      display: none;
    }

    label {
      width: 100%;
      height: 100%;
    }

    p-button {
      cursor: pointer;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: map.get(colors.$colors, "blue-900");
      }

      &:active {
        background-color: map.get(colors.$colors, "blue-850");
      }

      button.p-button {
        color: white;
        @include typography.pb-body-medium-compact;
        pointer-events: none;
      }
    }

    input[type="checkbox"] {
      position: absolute;
      opacity: 0;
      z-index: -1;
    }
  }

  // Collapsed menu
  &:has(input[type="checkbox"]:checked) {
    width: 4.5rem;

    div.p-menu-start {
      &:has(> a) a {
        > :first-child {
          display: none;
        }

        > :last-child {
          display: block;
        }
      }

      &:not(:has(> a)) {
        > :first-child {
          display: none;
        }

        > :last-child {
          display: block;
        }
      }

      img {
        width: 2rem;
      }
    }

    ul.p-menu-list {
      padding-inline: 0.5rem;

      li.p-menu-submenu-label {
        span {
          color: transparent;
          border-bottom: 1px solid white;
          opacity: 0.6;
          margin-inline: 0.5rem;
          max-width: 2.5rem;
          margin-block-end: 1rem;
        }
      }

      li.p-menu-item .p-menu-item-content .p-menu-item-link {
        padding-inline-start: 0;
        justify-content: center;

        .p-menu-item-label {
          display: none;
        }
      }
    }

    div.p-menu-end {
      label > :first-child {
        display: none;
      }
      label > :last-child {
        display: flex;
      }
    }
  }
}
