@use "sass:map";

$colors: (
  "white": #FFFFFF,

  "grey-900": #151E24,
  "grey-800": #243642,
  "grey-700": #3D5361,
  "grey-600": #566A76,
  "grey-500": #8A9DA8,
  "grey-400": #B6C5CE,
  "grey-300": #D6E0E6,
  "grey-200": #EAF0F4,
  "grey-100": #F7FAFC,
  
  "blue-900": #001B42,
  "blue-850": #002357,
  "blue-800": #003B84,
  "blue-700": #004AB1,
  "blue-600": #0059DD,
  "blue-500": #2B75E3,
  "blue-400": #5691E9,
  "blue-300": #81ADEF,
  "blue-200": #ACC9F5,
  "blue-100": #D7E5FB,
  
  "green-900": #154404,
  "green-800": #226C06,
  "green-700": #32A905,
  "green-600": #4EBF25,
  "green-500": #76D454,
  "green-400": #90D776,
  "green-300": #B4EF9F,
  "green-200": #CBF5BC,
  "green-100": #DFFED5,

  "yellow-900": #5B4701,
  "yellow-800": #997500,
  "yellow-700": #EDB403,
  "yellow-600": #F7C702,
  "yellow-500": #FFD422,
  "yellow-400": #FFDA42,
  "yellow-300": #FFE062,
  "yellow-200": #FFE88A,
  "yellow-100": #FFF6D1,

  "red-900": #5C0000,
  "red-800": #930000,
  "red-700": #C40000,
  "red-600": #EE0000,
  "red-500": #F23636,
  "red-400": #F45252,
  "red-300": #F89595,
  "red-200": #FBC0C0,
  "red-100": #FDE0E0
);

$pb-blue: map.get($colors, "blue-800");