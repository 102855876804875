@use "sass:map";
@use "../../design-tokens/colors";
@use "../../design-tokens/mixins/shadow";
@use "../../design-tokens/mixins/glow";

:root {
  --pb-dropdown-border-color: #{map.get(colors.$colors, "grey-400")};
  --pb-dropdown-hover-border-color: #{map.get(colors.$colors, "grey-500")};
  --pb-dropdown-focused-border-color: #{map.get(colors.$colors, "blue-700")};
  --pb-dropdown-disabled-border-color: #{map.get(colors.$colors, "grey-400")};
  --pb-dropdown-invalid-border-color: #{map.get(colors.$colors, "red-700")};
}

p-select.ng-dirty.ng-invalid > .p-select:not(.p-disabled) {
  border-color: var(--pb-dropdown-invalid-border-color);

  .p-select-trigger {
    color: map.get(colors.$colors, "red-700");
  }
}

p-select:not(.ng-dirty.ng-invalid) {
  > .p-select:not(.p-disabled):not(.p-select-open):not(.p-focus):hover {
    border-color: var(--pb-dropdown-hover-border-color);
  }
}

p-select.cdk-keyboard-focused .p-select {
  @include glow.glow(map.get(colors.$colors, "blue-400"));
}

.p-select {
  --p-select-background: white;
  --p-select-border-color: #{var(--pb-dropdown-border-color)};
  --p-select-border-radius: 2px;
  --p-select-padding-y: 9px;
  --p-select-padding-x: .5rem;
  --p-select-hover-border-color: #{var(--pb-dropdown-hover-border-color)};
  --p-select-dropdown-color: #{map.get(colors.$colors, "blue-700")};
  --p-select-clear-icon-color : #{map.get(colors.$colors, "blue-700")};
  --p-select-color: #{map.get(colors.$colors, "grey-800")};
  --p-select-invalid-border-color: #{map.get(colors.$colors, "red-700")};

  box-sizing: border-box;
  width: 100%;

  .p-select-dropdown {
    padding-right: .5rem;
  }

  &.p-inputwrapper-focus,
  &.p-select-open {
    border-color: var(--pb-dropdown-focused-border-color);
  }

  &.p-disabled {
    background: map.get(colors.$colors, "grey-200");
    border-color: var(--pb-dropdown-disabled-border-color);
  }

  .p-select-label {
    background: transparent;
    border: 0 none;
  }

  span.p-placeholder {
    color: map.get(colors.$colors, "grey-600");
  }

  .p-select-clear-icon {
    position: relative;
    display: flex;
    align-items: center;
    top: 0;
    margin: 0;
  }

  .p-select-trigger {
    width: 2rem;
    color: map.get(colors.$colors, "blue-700");
  }

  &.p-disabled .p-select-trigger {
    color: map.get(colors.$colors, "grey-600");
  }

  .p-overlay {
    margin-left: -1px;
  }

  &.ng-dirty:not(:hover) .p-select-dropdown {
    color: map.get(colors.$colors, "red-700");
  }

}

.p-select-overlay {
  background: white;
  border: 1px solid map.get(colors.$colors, "grey-300");
  border-radius: .25rem;
  cursor: default;
  @include shadow.shadow-high();
}

.p-select-items-wrapper {
  overflow: visible;
}

.p-select-items {
  padding-block: .5rem;
}

.p-select-option {
  padding: .75rem 1rem;
  color: map.get(colors.$colors, "grey-800");
  line-height: 1rem;

  &:not(.p-disabled):not(:active):hover {
    background: map.get(colors.$colors, "grey-200");
  }

  &.p-focus {
    @include glow.glow(map.get(colors.$colors, "blue-400"));
    z-index: 2;
  }

  &:active {
    background: map.get(colors.$colors, "grey-300");
  }

  &.p-disabled {
    color: map.get(colors.$colors, "grey-400");
  }
}
