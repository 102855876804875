@use "sass:map";

@use "../../design-tokens/colors" as colors;
@use "../../design-tokens/mixins/glow" as glow;

/* Primary icon button variables */
$icon-button-primary-color: map.get(colors.$colors, "blue-700");
$icon-button-primary-hover-bg: map.get(colors.$colors, "blue-100");
$icon-button-primary-active-bg: map.get(colors.$colors, "blue-200");
$icon-button-primary-disabled-color: map.get(colors.$colors, "blue-300");
$icon-button-primary-focus-shadow: map.get(colors.$colors, "blue-600");

/* Secondary icon button variables */
$icon-button-secondary-color: map.get(colors.$colors, "grey-600");
$icon-button-secondary-hover-bg: map.get(colors.$colors, "grey-200");
$icon-button-secondary-active-bg: map.get(colors.$colors, "grey-300");
$icon-button-secondary-disabled-color: map.get(colors.$colors, "grey-400");
$icon-button-secondary-focus-shadow: map.get(colors.$colors, "grey-600");

/* Danger icon button variables */
$icon-button-danger-color: map.get(colors.$colors, "red-700");
$icon-button-danger-hover-bg: map.get(colors.$colors, "red-100");
$icon-button-danger-active-bg: map.get(colors.$colors, "red-200");
$icon-button-danger-disabled-color: map.get(colors.$colors, "red-300");
$icon-button-danger-focus-shadow: map.get(colors.$colors, "red-600");

.p-button {
  &.p-button-icon-only, &.p-dialog-header-icon {
    width: 2rem;
    height: 2rem;
    padding: 0;
    border-radius: 100%;
    padding-inline-start: 0 !important;
    background-color: transparent;
    box-shadow: none;

    &:has(.p-button-icon) .p-button-icon {
      margin-inline-end: 0;
    }

    /* Primary icon button styles */
    color: $icon-button-primary-color;

    &:not(:disabled):hover {
      background: $icon-button-primary-hover-bg;
    }

    &:not(:disabled):active {
      background: $icon-button-primary-active-bg;
    }

    &:disabled {
      color: $icon-button-primary-disabled-color;
    }

    &:not(:disabled):focus-visible {
      @include glow.glow($icon-button-primary-focus-shadow);
    }

    /* Secondary icon button styles */
    &.p-button-secondary, &.p-dialog-header-icon {
      color: $icon-button-secondary-color;

      &:not(:disabled):hover {
        background: $icon-button-secondary-hover-bg;
      }

      &:not(:disabled):active {
        background: $icon-button-secondary-active-bg;
      }

      &:not(:disabled):focus-visible {
        @include glow.glow($icon-button-secondary-focus-shadow);
      }

      &:disabled {
        color: $icon-button-secondary-disabled-color;
      }
    }

    /* Danger icon button styles */
    &.p-button-danger {
      color: $icon-button-danger-color;

      &:not(:disabled):hover {
        background: $icon-button-danger-hover-bg;
      }

      &:not(:disabled):active {
        background: $icon-button-danger-active-bg;
      }

      &:not(:disabled):focus-visible {
        @include glow.glow($icon-button-danger-focus-shadow);
      }

      &:disabled {
        color: $icon-button-danger-disabled-color;
      }
    }
  }
}
