// Greys
$grey-100: #f7fafc;
$grey-200: #eaf0f4;
$grey-300: #d6e0e6;
$grey-400: #b6c5ce;
$grey-500: #8d9ba5;
$grey-600: #5a6872;
$grey-700: #3d5361;
$grey-800: #243642;
$grey-900: #151e24;

// Blue
$blue-100: #d7e5fb;
$blue-200: #acc9f5;
$blue-300: #81adef;
$blue-400: #5691e9;
$blue-500: #2b75e3;
$blue-600: #0059dd;
$blue-700: #004ab1;
$blue-800: #003b84;
$blue-850: #002357;
$blue-900: #001b42;

// Green
$green-100: #dffed5;
$green-200: #cbf5bc;
$green-300: #b4ef9f;
$green-400: #90d776;
$green-500: #76d454;
$green-600: #4ebf25;
$green-700: #32a905;
$green-800: #226c06;
$green-900: #154404;

// Yellow
$yellow-100: #fff6d1;
$yellow-200: #ffe88a;
$yellow-300: #ffe062;
$yellow-400: #ffda42;
$yellow-500: #ffd422;
$yellow-600: #f7c702;
$yellow-700: #edb403;
$yellow-800: #997500;
$yellow-900: #5b4701;

// Red
$red-100: #fde0e0;
$red-200: #fbc0c0;
$red-300: #f89595;
$red-400: #f45252;
$red-500: #f23636;
$red-600: #ee0000;
$red-700: #c40000;
$red-800: #930000;
$red-900: #5c0000;

// Purple
$purple-100: #EADCF8;
$purple-200: #D6B9F9;
$purple-300: #C399F3;
$purple-400: #A679E0;
$purple-500: #8D5CE6;
$purple-600: #9747FF;
$purple-700: #6D2CC0;
$purple-800: #4D1C9E;
$purple-900: #2B0B6B;

// PB Brand
$pb-orange: #ff4f00;
$pb-blue: $blue-850;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1280px,
  xl: 1600px,
  xxl: 1920px
) !default;
