@use "sass:map";
@use "../../design-tokens/mixins/glow" as glow;
@use "../../design-tokens/typography" as typography;
@use '../../design-tokens/colors';

p-checkbox {
  margin: 0.5rem;
}

.p-checkbox {
  --p-checkbox-border-radius: 2px;
  --p-checkbox-border-color: #{map.get(colors.$colors, "grey-400")};
  --p-checkbox-width: 1rem;
  --p-checkbox-height: 1rem;
  --p-checkbox-background: white;
  --p-checkbox-hover-border-color: #{map.get(colors.$colors, "grey-400")};

  --p-checkbox-disabled-background: #{map.get(colors.$colors, "grey-300")};

  --p-checkbox-checked-background: #{map.get(colors.$colors, "blue-700")};
  --p-checkbox-checked-border-color: #{map.get(colors.$colors, "blue-700")};
  --p-checkbox-checked-hover-border-color: #{map.get(colors.$colors, "blue-700")};
  --p-checkbox-checked-hover-background: #{map.get(colors.$colors, "blue-700")};
  --p-checkbox-icon-checked-hover-color: white;
  --p-checkbox-checked-disabled-border-color: #{map.get(colors.$colors, "blue-300")};

  --p-checkbox-icon-checked-color: white;
  --p-checkbox-icon-size: 12px;

  &:hover {
    box-shadow: 0 0 0 32px map.get(colors.$colors, "blue-100");
    clip-path: circle(100%);
  }

  &.p-highlight {
    &.p-disabled {
      background-color: map.get(colors.$colors, "blue-300");
    }
  }

  :not(.p-checkbox-checked) .p-checkbox-box {
    border-color: var(--p-checkbox-border-color);
  }

  &:active {
    background-color: map.get(colors.$colors, "blue-200");
  }
}

.p-checkbox:not(.p-disabled):hover .p-checkbox-box {
  background: map.get(colors.$colors, "blue-100");
}

.p-checkbox:not(.p-disabled):hover.p-checkbox-checked .p-checkbox-box {
  background: map.get(colors.$colors, "blue-700");
}

.p-checkbox:not(.p-disabled):active .p-checkbox-box {
  box-shadow: 0 0 10px 32px map.get(colors.$colors, "blue-200");
  background: map.get(colors.$colors, "blue-200");
  clip-path: circle(100%);
}

.p-checkbox .p-checkbox-box.p-focus::before {
  content: '';
  position: absolute;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  @include glow.glow(map.get(colors.$colors, "blue-500"));
}
