@use "sass:map";
@use "../../design-tokens/typography" as typography;
@use "../../design-tokens/colors" as colors;
@use "../../design-tokens/mixins/shadow" as shadow;
@use "../../design-tokens/mixins/glow" as glow;

// Variables for commonly used colors
$button-hover-bg-light: map.get(colors.$colors, "blue-100");
$button-border-hover: map.get(colors.$colors, "blue-200");
$button-disabled-bg-light: map.get(colors.$colors, "blue-300");
$button-focus-shadow: map.get(colors.$colors, "blue-600");
$button-bg-primary: map.get(colors.$colors, "blue-700");
$button-hover-bg: map.get(colors.$colors, "blue-800");
$button-active-bg: map.get(colors.$colors, "blue-900");

$danger-hover-bg-light: map.get(colors.$colors, "red-100");
$danger-border-hover: map.get(colors.$colors, "red-200");
$button-disabled-bg-danger: map.get(colors.$colors, "red-300");
$danger-focus-shadow: map.get(colors.$colors, "red-600");
$button-bg-danger: map.get(colors.$colors, "red-700");
$button-hover-bg-danger: map.get(colors.$colors, "red-800");
$button-active-bg-danger: map.get(colors.$colors, "red-900");

$button-focus-border: map.get(colors.$colors, "grey-300");

// Base Button Styles
p-button {
  display: inline-block;
}

.p-button {
  @include typography.pb-body-medium-compact;

  height: 2.5rem;
  padding: 0.75rem 1.25rem;
  color: #fff;
  background: $button-bg-primary;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  @include shadow.shadow-low;
  cursor: pointer;

  &:enabled {
    &:hover {
      background: $button-hover-bg;
    }
    &:active {
      background: $button-active-bg;
    }
  }

  &:disabled {
    background: $button-disabled-bg-light;
  }

  &:focus-visible {
    border-color: #fff;
    outline: 0 none;
    outline-offset: 10px;
    @include glow.glow;
  }

  // Outlined Modifier
  &-outlined {
    color: $button-bg-primary;
    background: transparent;
    border-color: $button-border-hover;

    &:enabled {
      &:hover {
        background: $button-hover-bg-light;
      }
      &:active {
        background: $button-border-hover;
      }
    }

    &:disabled {
      color: $button-disabled-bg-light;
      background: transparent;
    }
    &:focus-visible {
      border-color: $button-focus-border;
    }
  }

  // Text Modifier
  &-text {
    color: $button-bg-primary;
    background: transparent;
    box-shadow: none;
    padding-inline: 0;

    &:enabled {
      &:hover {
        background: transparent;
        .p-button-label {
          text-decoration: underline;
        }
      }
      &:active {
        color: $button-hover-bg;
        background: transparent;
        .p-button-label {
          text-decoration: underline;
        }
      }
    }

    &:disabled {
      color: $button-disabled-bg-light;
      background: transparent;
    }
    &:focus-visible {
      box-shadow: 0 0 5px 1px $button-focus-shadow;
    }
  }

  // Danger Modifier
  &-danger {
    background: $button-bg-danger;

    &:enabled {
      &:hover {
        background: $button-hover-bg-danger;
      }
      &:active {
        background: $button-active-bg-danger;
      }
      &:focus-visible {
        @include glow.glow($danger-focus-shadow);
      }
    }

    &:disabled {
      background: $button-disabled-bg-danger;
    }

    // Nested Modifiers for Outlined and Text Danger
    &.p-button-outlined {
      color: $button-bg-danger;
      background: transparent;
      border-color: $danger-border-hover;

      &:enabled {
        &:hover {
          background: $danger-hover-bg-light;
        }
        &:active {
          background: $danger-border-hover;
        }
      }

      &:disabled {
        color: $button-disabled-bg-danger;
      }
    }

    &.p-button-text {
      color: $button-bg-danger;
      background: transparent;
      box-shadow: none;

      &:enabled {
        &:hover {
          background: transparent;
          .p-button-label {
            text-decoration: underline;
          }
        }
        &:active {
          color: $button-hover-bg-danger;
          background: transparent;
          .p-button-label {
            text-decoration: underline;
          }
        }
      }

      &:disabled {
        color: $button-disabled-bg-danger;
      }
    }
  }

  // Icon Support
  &:has(.p-button-icon) {
    padding-block: 0.5rem;

    &:not(.p-button-text) {
      padding-inline-start: 0.75rem;
    }

    .p-button-icon {
      font-size: 1.125rem;
      margin-inline-end: 0.5rem;
      height: 1.5rem;
      width: 1.5rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }
}

p-button {
  &:has(.p-button .p-button-icon-right) {
    .p-button {
      &:not(.p-button-text) {
        padding-inline-end: 0.75rem;
        padding-inline-start: 1.25rem;
      }

      .p-button-icon.p-button-icon-right {
        margin-inline-end: 0;
        margin-inline-start: 0.5rem;
      }
    }
  }
}
