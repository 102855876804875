@use "sass:map";
@use '../../design-tokens/colors';
@use '../../design-tokens/mixins/glow';

.p-inputtext {
  background: white;
  border: 1px solid map.get(colors.$colors, "grey-400");
  border-radius: 2px;
  padding: calc((1.5rem - 2px) / 2) 1rem;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1rem;
  box-sizing: border-box;
  color: map.get(colors.$colors, "grey-800");

  &::placeholder {
    font-family: Inter, sans-serif;
    color: map.get(colors.$colors, "grey-600");
  }

  &:hover {
    border-color: map.get(colors.$colors, "grey-500");
  }

  &:focus {
    outline: 0 none;
    border-color: map.get(colors.$colors, "blue-700");
  }

  &:disabled {
    background: map.get(colors.$colors, "grey-200");
    border-color: map.get(colors.$colors, "grey-400");
  }

  &.cdk-keyboard-focused {
    @include glow.glow(map.get(colors.$colors, "blue-400"));
  }

  &.ng-dirty.ng-invalid {
    border-color: map.get(colors.$colors, "red-700");
  }
}
