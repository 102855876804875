@use "sass:map";
@use "../../design-tokens/colors";

p-togglebutton {
  display: inline-block;
}

.p-togglebutton {
  --p-togglebutton-border-radius: 4px;
  --p-togglebutton-border-color: #{map.get(colors.$colors, "grey-400")};
  --p-togglebutton-background: white;

  --p-togglebutton-checked-color: #{map.get(colors.$colors, "blue-700")};
  --p-togglebutton-checked-border-color: #{map.get(colors.$colors, "blue-500")};
  --p-togglebutton-checked-background: #{map.get(colors.$colors, "blue-100")};

  --p-togglebutton-disabled-background: #{map.get(colors.$colors, "grey-200")};
  --p-togglebutton-disabled-border-color: #{map.get(colors.$colors, "grey-400")};
  --p-togglebutton-disabled-color: #{map.get(colors.$colors, "grey-500")};

  height: 2.5rem;

  &:active {
    border-color: map.get(colors.$colors, "grey-600");
  }

  &:hover:not(:active):not(.p-disabled) {
    border-color: map.get(colors.$colors, "grey-500");
  }

  &:focus-visible:not(.p-disabled) {
    border-color: map.get(colors.$colors, "grey-500");
  }

  &.p-togglebutton-checked {
    &.p-disabled {
      background: map.get(colors.$colors, "blue-100");
      color: map.get(colors.$colors, "blue-300");
      border-color: map.get(colors.$colors, "blue-200");
    }

    &:hover:not(:active):not(.p-disabled) {
      border-color: map.get(colors.$colors, "blue-600");
    }

    &:active {
      border-color: map.get(colors.$colors, "blue-700");
    }

    &:focus-visible:not(.p-disabled) {
      border-color: map.get(colors.$colors, "blue-500");
    }
  }

  .p-togglebutton-content {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}
